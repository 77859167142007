<template>
  <div class="row mb-2">
    <!-- <div class="col-md-12 mb-1">
      <span class="text-primary">วันที่ {{ data.lottoDate }}</span>
      <button class="btn btn-sm btn-outline-danger py-0 ml-2" @click="del">ลบ</button>
    </div> -->

    <!-- <div class="col-md-6">
      <Box class="sec-1" code="sec1" :data="data" @reload="reload" />
    </div> -->

    <div class="col">
      <Box class="sec-2" code="sec2" :data="data" @reload="reload" />
    </div>
  </div>
</template>
<script>
import Box from './Box'

import Swal from 'sweetalert2'

import LottoService from '@/services/lottoService'

export default {
  components: {
    Box
  },
  props: ['data'],
  computed: {
    sec1() {
      return this.data?.sec1 || {
        three: '',
        two: ''
      }
    },
    sec2() {
      return this.data?.sec2 || {
        three: '',
        two: ''
      }
    }
  },
  methods: {
    del() {
      Swal.fire({
        title: 'ยืนยันการลบ!!!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          LottoService.delRound(this.data.lottoDate)
          .then((response)=>{
            if(response.success) {
              return response?.data
            }else{
              throw new Error(response?.data)
            }
          })
          .catch((error)=>{
            Swal.showValidationMessage(error.message)
          })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'success'
          })
          .then(()=>{
            this.$emit('reload')
          })
        }
      })
    },
    reload() {
      this.$emit('reload')
    }
  }
}
</script>
<style lang="scss" scoped>
.sec-1 {
  background-color: rgba(0,73,152,.2);
}

.sec-2 {
  background-color: rgba(0,73,152,.1);
}
</style>
